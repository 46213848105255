<template>
  <ion-page>
  <ion-app>

      <sm-auth-header></sm-auth-header>

      <ion-content scroll="true" fullscreen="true" style="--padding-start:1em; --padding-end:1em;">
        <slot></slot>
      </ion-content>

  </ion-app>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonApp } from '@ionic/vue';
import { defineComponent } from 'vue';
import SmAuthHeader from "../common/SmAuthHeader";

export default defineComponent({
    name: "SmSecondaryLayout",

    components: {
      IonApp,
      IonContent,
      IonPage,
      SmAuthHeader
    },
})
</script>

<style scoped>

</style>
