<template>
  <ion-header class="ion-no-border sm-header">
    <ion-toolbar class="ion-no-padding">
      <div class="sm-toolbar-top">
        <img alt="logo" height="75" src="img/logo_white.svg">
      </div>

      <div class="sm-toolbar">
        <ion-title>{{ getHeaderTitle }}</ion-title>

        <div class="sm-toolbar-right">
          <span class="sm-toolbar-status-icon" :class="{'is-online': isOnline}"></span><span>Status: {{ isOnline ? 'online' : 'offline' }}</span>
        </div>
      </div>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import { defineComponent } from "vue";
import { IonHeader, IonTitle, IonToolbar } from '@ionic/vue'
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "SmAuthHeader",

  components: {
    IonHeader,
    IonTitle,
    IonToolbar,
  },

  created() {
    this.getCustomer();
  },

  computed: {
    ...mapGetters('auth', ['isOnline', 'isLoggedIn']),
    ...mapGetters('common', ['getHeaderTitle']),
  },

  methods: {
    ...mapActions('auth', ['getCustomer'])
  }
})

</script>

<style scoped>
  .sm-header {
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  }

  .sm-toolbar-top {
    padding: 6.7em 1em 1.6em;
    background: var(--ion-color-primary);
  }

  .sm-toolbar {
    background: var(--ion-color-primary-contrast);
    padding: 1.125rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dcdcdc;
  }

  .sm-toolbar .title-default {
    padding: 0;
    position: relative;
    text-align: left;
    font-size: 1rem;
    max-width: 75%;
    text-transform: uppercase;
  }

  .sm-toolbar .sm-toolbar-right {
    align-items: center;
    font-size: 0;
    min-width: 7rem;
  }

  .sm-toolbar .sm-toolbar-right span {
    display: inline-block;
    vertical-align: middle;
    font-size: 0.875rem;
  }

  .sm-toolbar .sm-toolbar-status-icon {
    display: block;
    height: 10px;
    width: 10px;
    margin-right: 0.3rem;
    background: var(--ion-color-warning);
    border-radius: 50%;
  }

  .sm-toolbar .sm-toolbar-status-icon.is-online {
    background: var(--ion-color-success);
  }
</style>
