import {toastController} from '@ionic/vue';
import {defineComponent} from 'vue';

export default defineComponent({
  methods: {
    async openToast(message, color = 'success', duration = 2000) {
      const toast = await toastController
        .create({
          message,
          color,
          duration
        })
      return toast.present();
    },

    parseJson(str) {
      try {
        return JSON.parse(str);
      } catch (e) {
        return false;
      }
    }
  }
});
