import {loadingController} from '@ionic/vue';
import {defineComponent} from 'vue';

export default defineComponent({
  methods: {
    async presentScreenLoading(message = 'Bitte warten...', cssClass = 'sm-screen-loading') {
      const loading = await loadingController
        .create({
          cssClass,
          message,
        });

      await loading.present();

      return loading;
    },

    dismissScreenLoading(loading) {
      loading.dismiss();
    }
  },
});
