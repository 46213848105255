<template>
  <sm-secondary-layout>
    <p class="sm-login-text">Herzlich Willkommen, bitte melden Sie sich an oder scannen Sie einen QR-Code ein.</p>
    <!-- Inputs -->

    <ion-item class="ion-margin-bottom" v-bind:class="{ 'has-warning': attemptSubmit && errorMessage }">
      <ion-icon :icon="alertCircle" color="danger" slot="end"></ion-icon>
      <ion-label position="stacked">Ihre E-Mail-Adresse</ion-label>
      <ion-input class="form-control form-control-warning" v-model="form.username" name="email" type="email" placeholder="E-Mail-Adresse" @ionFocus="onFocus()"></ion-input>
    </ion-item>

    <ion-item class="sm-login-password ion-margin-bottom" v-bind:class="{ 'has-warning': attemptSubmit && errorMessage }">
      <ion-icon :icon="alertCircle" color="danger" slot="end"></ion-icon>
      <ion-label position="stacked">Ihr Passwort</ion-label>
      <ion-input class="form-control form-control-warning" v-model="form.password" name="password" type="password"
                 placeholder="Passwort" @ionFocus="onFocus()"></ion-input>
    </ion-item>

    <div class="sm-login-error-message">
      <span v-if="errorMessage">{{ errorMessage }}</span>
    </div>

    <!-- Buttons -->
    <div class="sm-login-buttons">
      <ion-button class="sm-login-btn" @click="login" color="primary" expand="block">Anmelden</ion-button>
      <ion-button class="sm-login-btn" @click="() => router.push({name: 'scan-qr'})" color="secondary" expand="block">Ohne Anmeldung Scannen</ion-button>
    </div>
  </sm-secondary-layout>
</template>

<script>
import { IonLabel, IonInput, IonItem, IonButton, IonIcon } from '@ionic/vue';
import { alertCircle } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import {mapMutations, mapActions, mapGetters} from "vuex";
import ScreenLoadingMixin from '@/mixins/screen-loading.mixin';
import CommonMixin from '@/mixins/common.mixin';
import SmSecondaryLayout from "@/components/layout/SmSecondaryLayout";
import ApiService from '@/services/api.service';
import { LOGIN_API_PATH } from "@/constants/api.constant";

export default defineComponent({
  name: "Login",

  mixins: [ScreenLoadingMixin, CommonMixin],

  components: {
    SmSecondaryLayout,
    IonLabel,
    IonInput,
    IonItem,
    IonButton,
    IonIcon
  },

  setup() {
    const router = useRouter();
    return { router, alertCircle };
  },

  ionViewWillEnter() {
    this.setHeaderTitle('Authentifizierung');
  },

  data() {
    return {
      form: {
        username: '',
        password: '',
        pwa: '1'
      },
      attemptSubmit: false,
      errorMessage: ''
    }
  },

  computed: {
      ...mapGetters('auth', ['isOnline'])
  },

  methods: {
    ...mapMutations('auth', ['setContextToken', 'setSalesChannel']),
    ...mapMutations('common', ['setHeaderTitle']),
    ...mapActions('auth', ['getCustomer', 'getSalesChannel']),

    onFocus() {
      const isIOS = navigator.userAgent
      if (/iPad|iPhone|iPod/.test(isIOS)
          || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))
      {
        window.scrollTo(0, document.querySelector('.sm-header').clientHeight);
      }
    },

    async login() {
      const loading = await this.presentScreenLoading();

      try {
        this.attemptSubmit = true;
        const response = await ApiService.post(LOGIN_API_PATH, this.form);
        let contextToken = response.data.contextToken;

        this.dismissScreenLoading(loading);

        if (contextToken === 'missingRequiredFields') {
          this.errorMessage = 'Bitten geben Sie ein gültiges Passwort ein.';
        } else if (contextToken === 'accountNotExist') {
          this.errorMessage = 'Unter dieser E-Mail Adresse ist bei uns kein Konto registriert.';
        } else if (contextToken === 'b2cNotAllow') {
          this.errorMessage = 'Diese App erlaubt es B2C-Kunden nicht, auf sie zuzugreifen.';
        } else if (contextToken === 'accountNotActive') {
          this.errorMessage = 'Ihr Account ist inaktiv. Bitte senden Sie uns eine E-Mail an info@1A-Medizintechnik.de.'
        } else {
          this.setContextToken(contextToken);
          this.setSalesChannel(response.data.salesChannel);
          const redirectAfterLogin = localStorage.getItem('redirectAfterLogin');
          if (!redirectAfterLogin) {
            await this.$router.push({name: 'scan-qr', replace: true});
            return;
          }

          await this.$router.push({name: redirectAfterLogin, replace: true});
          localStorage.removeItem('redirectAfterLogin');

          this.errorMessage = '';
        }
      } catch (e) {
        this.dismissScreenLoading(loading);

        if (!this.isOnline) {
          this.errorMessage = 'Login ohne Internetverbindung leider nicht möglich.';
        } else {
          this.errorMessage = 'Diese App erlaubt es B2C-Kunden nicht, auf sie zuzugreifen';
        }
      }
    },


  }
})
</script>

<style scoped>

.login-item {
  --background: transparent;
  --border-width: 0;
}

.form-control {
  --background: #fff;
  border: 1px solid #000;
  --padding-start: 0.6rem;
  --padding-end: 0.6rem;
  --padding-top: 0.6rem;
  --padding-bottom: 0.6rem;
}

.sc-ion-label-ios-h {
  transform: translateY(0) scale(1);
  margin-bottom: 0.5em;
  color: #4C5157;
}

.label-stacked.sc-ion-label-md-h {
  font-size: 1rem;
}

.item-inner {
  position: relative;
  display: block;
}

  .sm-login-text {
    margin: 1.5rem 0;
    line-height: 1.5;
  }

  .sm-login-error-message {
    min-height: 1rem;
    font-size: 0.875rem;
    margin-bottom: 1.25rem;
    color: var(--ion-color-danger);
  }

  .sm-login-buttons .sm-login-btn {
    margin-bottom: 0.875rem;
  }
  .sm-login-buttons button {
    box-shadow: 0 2px 2px 0 rgba(0,0,0, 0.3);
  }

  .sm-login-password {
    margin-bottom: 0.5rem;
  }

  .has-warning .form-control-warning {
    border-color: var(--ion-color-primary);
  }

  ion-icon {
    font-size: 20px;
    position: absolute;
    right: 0;
    bottom: 1px;
    z-index: 2;
    display: none;
  }

  .has-warning ion-icon {
    display: block;
  }

  .has-warning .form-control {
    padding-right: 30px !important;
  }
</style>
